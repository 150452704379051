import React from "react";

import Page from "../../components/Page";
import SEO from "../../components/SEO";
import { Carousel } from "../../components/Carousel";

import Img1 from "../../images/homeowners/benefits/slider-images/Img_HouseHolders_Benefits_1.jpg";
import Img2 from "../../images/homeowners/benefits/slider-images/Img_HouseHolders_Benefits_2.jpg";
import Img3 from "../../images/homeowners/benefits/slider-images/Img_HouseHolders_Benefits_3.jpg";
import Img4 from "../../images/homeowners/benefits/slider-images/Img_HouseHolders_Benefits_4.jpg";
import Img5 from "../../images/homeowners/benefits/slider-images/Img_Householders_Service.jpg";

import Icon1 from "../../images/homeowners/benefits/slider-icons/IconBenefits_Householders_01.svg";
import Icon2 from "../../images/homeowners/benefits/slider-icons/IconBenefits_Householders_02.svg";
import Icon3 from "../../images/homeowners/benefits/slider-icons/IconBenefits_Householders_03.svg";
import Icon4 from "../../images/homeowners/benefits/slider-icons/IconBenefits_Householders_04.svg";
import Icon5 from "../../images/homeowners/benefits/slider-icons/IconBenefits_Householders_05.svg";

import { homeowners } from "../../helpers/navigation";

const BenefitsPage = () => {
  const slides = [
    <div className="Page_main">
      <div className="Page_main_block">
        <p>
          Хто сказав, що будинок повинен тільки споживати? <br />
          Давайте почнемо <strong>заробляти кошти!</strong>
        </p>
        <p>
          Пропонуємо отримувати <strong>пасивний дохід</strong> від розміщення
          реклами на інформ-моніторах
        </p>
        <h6>Жодних зусиль + Ваш прибуток!</h6>
      </div>
      <div className="Page_main_block">
        <img src={Img1} className="Page_main_block_img" alt="Img1" />
      </div>
    </div>,
    <div className="Page_main">
      <div className="Page_main_block">
        <p>
          Нагадаємо <strong>боржникам</strong> будинку <br />
          про необхідність <strong>своєчасного розрахунку!</strong>
        </p>
        <p>
          Ми впевнені, більшість боржників <br />
          просто забувають
          <strong> вчасно оплатити послуги.</strong>
          <br />
          Сервіс допоможе <strong>повідомити і нагадати про це!</strong>
        </p>
        <h6>Нагадали = розрахувались</h6>
      </div>
      <div className="Page_main_block">
        <img src={Img2} className="Page_main_block_img" alt="Img1" />
      </div>
    </div>,
    <div className="Page_main">
      <div className="Page_main_block">
        <p>
          Мешканці будинку будуть проінформовані відносно{" "}
          <strong>актуальних та важливих подій.</strong>
        </p>
        <p>
          Завчасне попередження про ремонт обладнання чи відключення
          комунікацій, оголошення загального характеру - Ви зможете донести
          будь-яку <strong>необхідну інформацію.</strong>
        </p>
        <h6>Praemonitus, praemonitus! :)</h6>
      </div>
      <div className="Page_main_block">
        <img src={Img3} className="Page_main_block_img" alt="Img1" />
      </div>
    </div>,
    <div className="Page_main">
      <div className="Page_main_block">
        <p>
          <strong>Сучасне цифрове рішення </strong> дозволить прибрати зайві
          паперові оголошення.
        </p>
        <p>
          Компактні кріплення не завдадуть шкоди, а монітори органічно
          вписуються в інтер'єр, що надає приміщенню{" "}
          <strong>охайний вигляд.</strong>
        </p>
        <h6>Елегантно та сучасно!</h6>
      </div>
      <div className="Page_main_block">
        <img src={Img4} className="Page_main_block_img" alt="Img1" />
      </div>
    </div>,
    <div className="Page_main">
      <div className="Page_main_block">
        <p>
          Ви отримуєте <strong>повноцінний сервіс</strong>
          <br />
          майже <strong>не прикладаючи жодних зусиль.</strong>
        </p>
        <p>
          Ми створюємо привабливі та інформативні оголошення, розміщуємо в
          системі, оперативно оновлюємо.
          <br /> Все, <strong>згідно ваших побажань!</strong>
        </p>
        <h6>Сервіс та якість!</h6>
      </div>
      <div className="Page_main_block">
        <img src={Img5} className="Page_main_block_img" alt="Img1" />
      </div>
    </div>,
  ];

  const sliderIcons = [<Icon1 />, <Icon2 />, <Icon3 />, <Icon4 />, <Icon5 />];
  let dots = [];

  for (let slideIndex = 0; slideIndex < slides.length; slideIndex++) {
    dots.push(<div className="Benefits_dots">{sliderIcons[slideIndex]}</div>);
  }

  return (
    <Page
      page="Benefits"
      pageName="Переваги"
      title="Переваги для Вас"
      parameters={homeowners}
    >
      <SEO title="Переваги" />

      <Carousel slides={slides} dots={dots} />
    </Page>
  );
};

export default BenefitsPage;
